import React, { useEffect } from "react";
import {
    EventMessage,
    EventType,
    PublicClientApplication,
    NavigationClient,
    NavigationOptions,
} from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./Infrastructure/Services/NexusAuthService";
import { useNavigate, NavigateFunction } from "react-router-dom";
import env from "@beam-australia/react-env";
import { analyticsService } from "./Infrastructure/Services/AnalyticsService";

export class CustomNavigationClient extends NavigationClient {
    private readonly navigate: NavigateFunction;
    constructor(navigate: NavigateFunction) {
        super();
        this.navigate = navigate;
    }
    /**
     * Navigates to other pages within the same web application
     * You can use the useNavigate hook provided by react-router-dom to take advantage of client-side routing
     * @param url
     * @param options
     */
    async navigateInternal(url: string, options: NavigationOptions) {
        const relativePath = url.replace(`${window.location.origin}${env("ROUTER_BASE_PATH")}`, "");
        if (options.noHistory) {
            this.navigate(relativePath, { replace: true });
        } else {
            this.navigate(relativePath);
        }

        return false;
    }
}

const hookupLoginEventsToTelemetry = (
    clientApp: PublicClientApplication
) => {
    const parentEvent = "session.initiated";
    const callbackId = clientApp.addEventCallback((event: EventMessage) => {
        if (event.eventType === EventType.LOGIN_START) {
            analyticsService.trackAnalyticsEvent(
                "login.begin",
                {},
                parentEvent
            );
        } else if (event.eventType === EventType.LOGIN_SUCCESS) {
            analyticsService.trackAnalyticsEvent(
                "login.end",
                {},
                parentEvent
            );
        } else if (event.eventType === EventType.LOGOUT_SUCCESS) {
            analyticsService.trackAnalyticsEvent(
                "logout.end",
                {},
                parentEvent
            );
        }
    });
    return () => {
        if (callbackId) clientApp.removeEventCallback(callbackId);
    };
};

/**
 * Places the global msal instance in a react component
 * so it's accessible from the context, and allows easy
 * authentication.
 */
const Auth = (props: { children: React.ReactNode }) => {
    const navigate = useNavigate();
    useEffect(() => {
        const navigationClient = new CustomNavigationClient(navigate);
        msalInstance.setNavigationClient(navigationClient);
        const unregisterEvents = hookupLoginEventsToTelemetry(msalInstance);
        return () => {
            unregisterEvents();
        };
    }, [navigate]);

    return <MsalProvider instance={msalInstance}>{props.children}</MsalProvider>;
};

export default Auth;
