import { useQuery, UseQueryOptions } from "react-query";
import { BoundedContext } from "../Services/useHubConnection";
import { HttpError, query } from "./query";

interface QueryParameters<TData> {
    url: string;
    boundedContext: BoundedContext;
    notifyOnChangeOn?: string;
    validate?: (data: unknown) => TData;
}

type QueryKey = [BoundedContext, string | undefined, string];

type Options<TData, TSelector = TData> = Omit<
    UseQueryOptions<TData, HttpError, TSelector, QueryKey>,
    "queryKey" | "queryFn"
>;

/**
 * Returns data from cache or makes a request to endpooint if there is no cache (or stale cache).
 *
 * @param queryParameters The url to query, the boundedContext the url is from, notifyOnChangeOn to receive notifications on change on that entityId and validate to validate the received data
 * @param options Standard react-query options
 */
const useBaseQuery = <TData, TSelector = TData>(
    { url, boundedContext, notifyOnChangeOn, validate }: QueryParameters<TData>,
    options?: Options<TData, TSelector>
) => {
    return useQuery<TData, HttpError, TSelector, QueryKey>(
        [boundedContext, notifyOnChangeOn, url],
        () => query<TData>(url).then(data => (validate ? validate(data) : data)),
        options
    );
};

export { useBaseQuery, type QueryKey, type Options };
