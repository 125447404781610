import {
    ApplicationInsights,
    DistributedTracingModes,
    ITelemetryItem,
    ITelemetryPlugin,
    SeverityLevel,
} from "@microsoft/applicationinsights-web";
import { ReactPlugin, withAITracking } from "@microsoft/applicationinsights-react-js";
import { Telemetry } from "./Telemetry";
import { getEmail } from "./NexusAuthService";
import React from "react";
import env from "@beam-australia/react-env";

const reactPlugin: ReactPlugin = new ReactPlugin();
let appInsights: ApplicationInsights;
let telemetry: Telemetry;

let noahSessionId: string;
const noahSessionIdKey: string = "noahSessionIdKey";

let scenarioHash: string;
const scenarioHashKey: string = "scenarioHashKey";

export const getTrackingSessionId = () => {
    return noahSessionId;
};

const traceStateKey: string = "traceStateKey";
export const traceState = new Map<string, string>();

let uiActivityId: string;
let uiActivityParentId: string;

function extractSpanId(activityId: string): string {
    if (!activityId) return "";
    return activityId.substring(36, 52);
}

export const extractRootId = (activityId: string | null) => {
    if (!activityId) return "";
    return activityId.substring(3, 35);
};

const genRanHex = (size: number) =>
    //for syntax, see https://stackoverflow.com/questions/5501581/javascript-new-arrayn-and-array-prototype-map-weirdness
    [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join("");

export const startNewActivity = () => {
    uiActivityId = "00-" + genRanHex(32) + "-" + genRanHex(16) + "-00";
    uiActivityParentId = uiActivityId;
    setTraceIdsToActivityIds();
};

export const startDetachedActivity = (prefix2Chars: string = "XX") => {
    prefix2Chars = prefix2Chars.substring(0, 2);
    uiActivityId =
        prefix2Chars +
        "-" +
        prefix2Chars +
        genRanHex(30) +
        "-" +
        prefix2Chars +
        genRanHex(14) +
        "-" +
        prefix2Chars;
    uiActivityParentId = uiActivityId;
    setTraceIdsToActivityIds();
};

const createTelemetryService = () => {
    const initialize = (instrumentationKey: string) => {
        if (!instrumentationKey) {
            throw new Error("Instrumentation key not provided in ./src/telemetry-provider.jsx");
        }
        appInsights = new ApplicationInsights({
            config: {
                instrumentationKey,
                distributedTracingMode: DistributedTracingModes.W3C,
                enableCorsCorrelation: true,
                extensions: [reactPlugin, new ContextSetupPlugin()],
            },
        });

        appInsights.loadAppInsights();

        appInsights.context.application.ver = env("VERSION");
        appInsights.context.application.build = env("VERSION")?.split(".")?.pop() ?? env("VERSION");

        const telemetryInitializer = (envelope: ITelemetryItem) => {
            envelope.tags = envelope.tags || [];
            envelope.tags.push({ "ai.cloud.role": "Frontend" });
        };

        const blacklistedExceptionMessages: Array<string> = (
            env("FE_AI_LOG_BLACKLISTED_EXCEPTION_MESSAGES") ?? ""
        ).split(",");

        const filterBlacklistedExceptions = exceptionFilterPlugin(blacklistedExceptionMessages);

        appInsights.addTelemetryInitializer(telemetryInitializer);
        appInsights.addTelemetryInitializer(filterBlacklistedExceptions);

        let savedNoahSessionId = sessionStorage.getItem(noahSessionIdKey);

        startDetachedActivity("DF"); //DF <= default session id (will be overwritten by URL from Noah module with setNoahSessionId())
        if (!savedNoahSessionId) {
            savedNoahSessionId = extractRootId(uiActivityId);
            sessionStorage.setItem(noahSessionIdKey, savedNoahSessionId);
        }
        const savedTraceStateString = sessionStorage.getItem(traceStateKey);
        if (savedTraceStateString) {
            setTraceStateString(savedTraceStateString);
        }

        console.log("initial telemetry session_id: " + savedNoahSessionId);

        setSessionId(sessionStorage.getItem(noahSessionIdKey) ?? "no saved session id in UI");

        telemetry = new Telemetry(appInsights);
        return appInsights;
    };

    return { reactPlugin, appInsights, initialize };
};

class ContextSetupPlugin implements ITelemetryPlugin {
    // Github project reference & example:
    // https://github.com/microsoft/ApplicationInsights-JS#build-a-new-extension-for-the-sdk

    priority = 201; // lowest recommended priority
    identifier = "ContextSetupPlugin";
    public processTelemetry(env: ITelemetryItem, itemCtx?: any) {
        if (!env.data) env.data = {};
        if (!env.tags) env.tags = [];
        if (!env.baseData) env.baseData = {};

        const telemetryTraceContext = appInsights.context?.telemetryTrace;
        if (telemetryTraceContext !== undefined) {
            env.baseData.properties = env.baseData.properties ?? {};
            addValuesFromTraceState(env.baseData.properties);
        }

        itemCtx?.processNext(env);
    }

    public initialize() {
        // Disable warning. Function necessary to implement interface
    }
}

export const setSessionProperties = (trackingId: string, hcpId: string, hcpCountry: string) => {
    uiActivityParentId = trackingId;
    uiActivityId = createNewDerivedActivityId(trackingId);

    const rootId = extractRootId(uiActivityParentId);
    setSessionId(rootId);

    setHcpId(hcpId);
    setTestSource();

    appInsights.context.telemetryTrace.parentID = extractSpanId(uiActivityParentId);
    appInsights.context.telemetryTrace.traceID = rootId;

    setHcpCountry(hcpCountry);
};

export const setHcpCountry = (hcpCountry: string) => {
    if (hcpCountry) setTraceStateValue("hcpCountry", hcpCountry);
};

export const setTestSource = () => {
    const email = getEmail();

    if (email?.includes("test_hcp")) {
        setTraceStateValue("testSource", "SWSystem");
        if (email?.includes("solution_")) {
            setTraceStateValue("testSource", "End2End");
        }
    }
};

export const trackEvent = (
    telemetryClient: Telemetry,
    eventName: string,
    dictionary: { [name: string]: string } = {}
) => {
    if (appInsights) {
        try {
            setTraceIdsToActivityIds();
            dictionary["logType"] =
                dictionary["logType"] !== undefined
                    ? `${dictionary["logType"]},debugAnalytics`
                    : "debugAnalytics";
            telemetryClient.trackEvent(eventName, dictionary);
        } finally {
            appInsights.context.telemetryTrace.parentID = extractSpanId(uiActivityParentId);
            appInsights.context.telemetryTrace.traceID = extractRootId(uiActivityId);
        }
    }
};

export const trackTrace = (
    telemetryClient: Telemetry,
    message: string,
    severity: SeverityLevel,
    dictionary: { [name: string]: string } = {}
) => {
    if (appInsights) {
        try {
            setTraceIdsToActivityIds();
            dictionary["logType"] = "debug";
            telemetryClient.trackTrace(message, severity, dictionary);
        } finally {
            appInsights.context.telemetryTrace.parentID = extractSpanId(uiActivityParentId);
            appInsights.context.telemetryTrace.traceID = extractRootId(uiActivityId);
        }
    }
};

export const trackException = (
    telemetryClient: Telemetry,
    exception: Error,
    dictionary: { [name: string]: string } = {}
) => {
    if (appInsights) {
        try {
            setTraceIdsToActivityIds();
            dictionary["logType"] =
                dictionary["logType"] !== undefined
                    ? `${dictionary["logType"]},regulatory,debug,debugAnalytics`
                    : "regulatory,debug,debugAnalytics";
            telemetryClient.trackException(exception, dictionary);
        } finally {
            appInsights.context.telemetryTrace.parentID = extractSpanId(uiActivityParentId);
            appInsights.context.telemetryTrace.traceID = extractRootId(uiActivityId);
        }
    }
};

export const startTrackPage = (telemetryClient: Telemetry, eventName: string) =>
    telemetryClient.startTrackPage(eventName);

export const stopTrackPage = (
    telemetryClient: Telemetry,
    eventName: string,
    url: string,
    dictionary: { [name: string]: string } = {}
) => {
    dictionary["logType"] = "debugAnalytics";
    telemetryClient.stopTrackPage(eventName, url, dictionary);
};

export const setTraceStateValue = (key: string, value: string) => {
    traceState.set(key, value);
};

export const addValuesFromTraceState = (dictionary: { [name: string]: string }) => {
    if (!traceState) return;

    traceState.forEach((value, key) => {
        dictionary[key] = value;
    });
};

export const getTraceStateString = () => {
    let traceStateString = "";

    if (traceState) {
        traceState.forEach((value, key) => {
            traceStateString = traceStateString + key + "=" + value + ",";
        });
    }

    return traceStateString;
};

export const exceptionFilterPlugin = (blacklistedExceptionMessages: string[]) => {
    return (telemetryItem: ITelemetryItem) => {
        const ex = telemetryItem?.baseData?.exceptions?.[0];
        if (ex) {
            const isBlacklisted = blacklistedExceptionMessages.some(blacklistedMessage => {
                if (!blacklistedMessage) return false;
                return ex.message.includes(blacklistedMessage);
            });
            if (isBlacklisted) {
                console.log(`${ex.message} - filtered out, not logged to AI`);
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    };
};

function setTraceIdsToActivityIds() {
    if (appInsights) {
        appInsights.context.telemetryTrace.parentID = extractSpanId(uiActivityParentId);
        appInsights.context.telemetryTrace.traceID = extractRootId(uiActivityId);
    }
}

function setTraceStateString(traceStateString: string) {
    traceStateString.split(",").forEach(pair => {
        const keyvalue = pair.split("=");
        if (keyvalue.length >= 2) {
            setTraceStateValue(keyvalue[0], keyvalue[1]);
        }
    });
}

export const getDateAsISOString = () => new Date(Date.now()).toISOString();

export const ai = createTelemetryService();
export const getAppInsights = () => appInsights;
export const getTelemetry = () => telemetry;
export const withTracking = <T>(
    Component: React.ComponentType<T>,
    ComponentName: string | null = null
) => {
    if (ComponentName) {
        return withAITracking(reactPlugin, Component, ComponentName);
    } else {
        return withAITracking(reactPlugin, Component, Component.name);
    }
};

window.addEventListener("beforeunload", e => {
    sessionStorage.closedLastTab = "1";
    console.log(
        `ApplicationInsight: beforeunload ; noahSessionId: ${sessionStorage.getItem(
            noahSessionIdKey
        )}, traceStateString: ${sessionStorage.getItem(traceStateKey)}`
    );
});
window.addEventListener("load", e => {
    console.log(
        `ApplicationInsight: load ; noahSessionId: ${sessionStorage.getItem(
            noahSessionIdKey
        )}, traceStateString: ${sessionStorage.getItem(traceStateKey)}`
    );
});
export const startNewActivityForTab = () => {
    if (!sessionStorage.tabID || sessionStorage.closedLastTab === "2") {
        sessionStorage.tabID = Math.random();
        startNewActivity();
    }
    sessionStorage.closedLastTab = "2";
};

const createNewDerivedActivityId = (operationParentId: string) => {
    if (operationParentId.length === 0) {
        operationParentId = "00-" + genRanHex(32) + "-DF" + genRanHex(14) + "-00";
    }
    return operationParentId.substring(0, 36) + genRanHex(16) + operationParentId.substring(52);
};

export const startNewDerivedActivity = (activityParentId: string | null = null) => {
    if (activityParentId) {
        uiActivityParentId = activityParentId;
    }

    uiActivityId = createNewDerivedActivityId(uiActivityParentId);
    setTraceIdsToActivityIds();
};

export const startNamedTracking = (idName: string, telemetryClient?: Telemetry) => {
    setTraceStateValue("trackingId" + idName, genRanHex(24));
    if (telemetryClient) {
        trackEvent(telemetryClient, "NamedTracking.Start." + idName);
    }
};

export const setSessionId = (sessionId: string) => {
    noahSessionId = sessionId; //expecting 32 char HEX string.
    sessionStorage.setItem(noahSessionIdKey, noahSessionId);
    setTraceStateValue("noahSessionId", noahSessionId);
    appInsights.context.session.id = noahSessionId;
};

export const getSessionId = () => {
    return sessionStorage.getItem(noahSessionIdKey);
};

export const setScenarioHash = (hash: string) => {
    scenarioHash = hash;
    sessionStorage.setItem(scenarioHashKey, scenarioHash);
    setTraceStateValue("scenarioHash", scenarioHash);
};

export const setHcpId = (hcpId: string) => {
    if (!hcpId) hcpId = "BB" + genRanHex(30);
    setTraceStateValue("hcpId", hcpId);
    appInsights.context.user.id = hcpId;
};

export const setConsenteeId = (consenteeId: string) => {
    setTraceStateValue("consenteeId", consenteeId);
};
