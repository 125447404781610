import { ThunkAction } from "redux-thunk";
import notificationBarReducer from "./BoundedContext/FineTuning/State/notificationReducer";
import patientProfileReducer from "./BoundedContext/PatientProfile/State";
import sessionReducer from "./BoundedContext/PatientManagement/State/reducers";
import deviceReducer from "./State/Devices/reducers";
import performanceGraphReducer from "./BoundedContext/performanceGraphs/State/reducers";
import { configureStore, combineReducers, Action } from "@reduxjs/toolkit";

export const rootReducer = combineReducers({
    notificationBar: notificationBarReducer,
    patientProfile: patientProfileReducer,
    sessionReducer,
    devices: deviceReducer,
    performanceGraph: performanceGraphReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export type SyncAppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action
>;

export type AppThunk<ReturnType = void> = ThunkAction<
    Promise<ReturnType>,
    RootState,
    unknown,
    Action
>;

export const initialState: RootState = rootReducer(undefined as any, {} as any);

export const createRootStore = (options?: { enableLogging?: boolean }) => {
    return configureStore({
        reducer: rootReducer,
        preloadedState: initialState,
        devTools:
            process.env.NODE_ENV === "development" &&
            typeof window !== "undefined" &&
            // eslint-disable-next-line no-underscore-dangle
            (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__,
    });
};
