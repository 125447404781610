import { NexusVersionData } from "../../BoundedContext/ApplicationConfiguration/hooks/useGetBackendVersionQuery";
import { eventContext } from "./EventContext/EventContext";
import { logVerbose } from "./LoggingService";
import Telemetry from "./Telemetry";
import { telemetryCustomDataProvider } from "./TelemetryCustomDataProvider";
import {
    trackEvent,
    stopTrackPage as stopTrackPageAI,
    startTrackPage as startTrackPageAI,
    trackException,
} from "./ApplicationInsights";
import isEqual from "lodash/isEqual";

const connectionChange = "connection.change";
const selectionChange = "selection.change";

/**
 * Analytics wraps Application Insigths and adds schema-version, event-context and feature-data to customDimensions
 * before calling Application Insights trackEvent(..).
 */
class AnalyticsService {
    /**
     * The event-schema to match all events against (when calling trackEvent(..))
     */
    //private readonly schema = new EventSchema();

    initalize(telemetryClient: Telemetry) {
        this.telemetryClient = telemetryClient;
    }

    private telemetryClient: Telemetry = new Telemetry(null);
    /**
     * Gets the event version from the schema (or no version if no schema match) and sets the event context.
     * Then calls the wrapped Application Insights trackEvent(..) with the name, version, context and feature data.
     * The following line is suppose to be added when we have schema versioning // let version = this.schema.getVersion(name, feature);
     * @param name - the name of the event to track
     * @param feature - the data of the event to track
     * @param parent - the name of the parent event of the event given by the 'name' parameter
     * @param logType - the type of the logged item
     */
    trackAnalyticsEvent(
        name: string,
        feature: any,
        parent: string | undefined,
        logType: any = undefined
    ): void {
        eventContext.replace(name, feature, parent);
        const context = eventContext.getContextForParticularEvent(name);
        const eventParent = eventContext.getParent(name) as any;
        const customDimensionsProperties = { eventParent, context, feature, logType };
        logVerbose(`trackAnalyticsEvent: ${JSON.stringify({ name, customDimensionsProperties })}`);
        trackEvent(this.telemetryClient, name, customDimensionsProperties);
    }

    trackAnalyticsException(
        exception: Error,
        feature: any,
        parent: string | undefined,
        logType: any = undefined
    ): void {
        eventContext.replace(exception.name, feature, parent);
        const context = eventContext.getContextForParticularEvent(exception.name);
        const eventParent = eventContext.getParent(exception.name) as any;
        const hearingAidsAndVersionProperties =
            telemetryCustomDataProvider.getHearingAidsAndVersionProperties();
        const combinedFeatureProperties = { ...feature, ...hearingAidsAndVersionProperties };
        const customDimensionsProperties = {
            eventParent,
            context,
            feature: combinedFeatureProperties,
            logType,
        };
        logVerbose(
            `trackAnalyticsException: ${JSON.stringify({
                name: exception.name,
                customDimensionsProperties,
            })}`
        );
        trackException(this.telemetryClient, exception, customDimensionsProperties);
    }

    extractHearingAidId(url: string): string | null {
        const regex = /hearingAidId=([a-zA-Z0-9-]+)/;
        const match = regex.exec(url);
        if (match && match[1]) {
            return match[1];
        }

        return null;
    }

    trackConnectionChangeEvent(data: any, queryKeyUrl: string) {
        const hearingAidId = this.extractHearingAidId(queryKeyUrl);
        telemetryCustomDataProvider.setConnectionStatus(
            hearingAidId!,
            data.isReadyForProgramming,
            data.isPhysicallyConnected
        );

        this.trackAnalyticsEvent(
            connectionChange,
            telemetryCustomDataProvider.getConnectionChangeProperties(),
            selectionChange
        );
    }

    trackVersionKnownEvent(data: any, queryKeyUrl: string) {
        const versionData = data as NexusVersionData;
        telemetryCustomDataProvider.setBackendVersion(versionData?.buildVersion);
        this.trackAnalyticsEvent(
            "version.known",
            //eslint-disable-next-line camelcase
            telemetryCustomDataProvider.getVersionProperties(),
            "session.initiated"
        );
    }

    trackSelectionChangeEvent(data: any, queryKeyUrl: string) {
        const previousHearingAidsProperties =
            telemetryCustomDataProvider.getHearingAidsProperties();
        telemetryCustomDataProvider.setAssignedHearingAids(data);
        const currentHearingAidsProperties = telemetryCustomDataProvider.getHearingAidsProperties();
        if (!isEqual(previousHearingAidsProperties, currentHearingAidsProperties))
            this.trackAnalyticsEvent(
                selectionChange,
                currentHearingAidsProperties,
                "session.begin"
            );
    }

    trackConnectionFaultyStateEvent(data: any, queryKeyUrl: string) {
        const leftHearingAidId = data?.leftHaFaultyState?.id;
        if (leftHearingAidId)
            telemetryCustomDataProvider.setConnectionStatus(leftHearingAidId, false, false);
        const rightHearingAidId = data?.rightHaFaultyState?.id;
        if (rightHearingAidId)
            telemetryCustomDataProvider.setConnectionStatus(rightHearingAidId, false, false);

        if (leftHearingAidId || rightHearingAidId) {
            this.trackAnalyticsEvent(
                connectionChange,
                telemetryCustomDataProvider.getConnectionChangeProperties(),
                selectionChange
            );
            this.trackAnalyticsEvent("connection.faulted_state", { data }, connectionChange);
        }
    }

    startTrackPage(eventName: string) {
        startTrackPageAI(this.telemetryClient, eventName);
    }

    stopTrackPage(eventName: string, dictionary: { [name: string]: string } = {}) {
        stopTrackPageAI(this.telemetryClient, eventName, window.location.href, dictionary);
    }
}

export const analyticsService = new AnalyticsService();
