import { AxiosHeaders, AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { getTraceStateString } from "./ApplicationInsights";
import { getAccessToken } from "./NexusAuthService";

export class ServiceConfigFactory {
    public static async createAxiosConfigWithAuth(): Promise<AxiosRequestConfig> {
        const token = await getAccessToken();
        const headers = ServiceConfigFactory.returnHeadersObject(token);

        return {
            // validateStatus: _ => true, // keep default. status >= 200 && status < 300;
            // timeout: 10000, // keep default -> 0 (infinity)
            headers,
        };
    }

    public static createAxiosConfigWithoutAuth(): AxiosRequestConfig {
        const headers = ServiceConfigFactory.returnHeadersObject();
        return {
            headers,
        };
    }

    private static returnHeadersObject(token?: string): AxiosRequestHeaders {
        return new AxiosHeaders({
            "Content-Type": "application/json",
            Authorization: token ? `Bearer ${token}` : "",
            tracestate: getTraceStateString(),
            "Content-Security-Policy": "default-src 'self';",
            "X-Frame-Options": "SAMEORIGIN",
            "X-XSS-Protection": "1; mode=block",
            "X-Content-Type-Options": "nosniff",
        }) as AxiosRequestHeaders;
    }
}
