/* eslint-disable camelcase */
import {
    createHearingAid,
    getHearingAidBySide,
    HearingAids,
} from "../../BoundedContext/PatientProfile/hooks/hearingAids/useAssignedHearingAidsSelector";
import { FittingStep } from "../../components/DeviceAssignment/FittingDialogContent";
import { EnvironmentInfoService } from "./EnvironmentInfoService";

export interface HearingAidsFirmwareVersion {
    left: string | null | undefined;
    right: string | null | undefined;
}

export interface ConnectionStatus {
    left: {
        id: string;
        isReadyForProgramming: boolean;
        isPhysicallyConnected: boolean;
    };
    right: {
        id: string;
        isReadyForProgramming: boolean;
        isPhysicallyConnected: boolean;
    };
}

class TelemetryCustomDataProvider {
    assignedHearingAids: HearingAids;
    backendVersion: string;
    hiFirmwareVersion: HearingAidsFirmwareVersion;
    targetChoice: FittingStep;
    connectionStatus: ConnectionStatus;
    connectionId: string;

    constructor() {
        this.assignedHearingAids = { left: null, right: null };
        this.backendVersion = "";
        this.hiFirmwareVersion = { left: null, right: null };
        this.targetChoice = "FITTING_CHOICE";
        this.connectionStatus = {
            left: { id: "", isPhysicallyConnected: false, isReadyForProgramming: false },
            right: { id: "", isPhysicallyConnected: false, isReadyForProgramming: false },
        };
        this.connectionId = "";
    }

    setAssignedHearingAids(hearingAids: any): void {
        this.assignedHearingAids = {
            left: createHearingAid(getHearingAidBySide(hearingAids, "Left")),
            right: createHearingAid(getHearingAidBySide(hearingAids, "Right")),
        };
    }
    setBackendVersion(backendVersion: string): void {
        this.backendVersion = backendVersion;
    }
    setHearingAidsFirmwareVersion(hearingAidsFirmwareVersion: HearingAidsFirmwareVersion): void {
        this.hiFirmwareVersion = hearingAidsFirmwareVersion;
    }
    setFittingChoice(targetChoice: FittingStep): void {
        this.targetChoice = targetChoice;
    }

    setConnectionStatus(
        hearingAidId: string,
        isReadyForProgramming: boolean,
        isPhysicallyConnected: boolean
    ): void {
        if (this.assignedHearingAids.left?.id === hearingAidId) {
            this.connectionStatus.left.id = hearingAidId;
            this.connectionStatus.left.isReadyForProgramming = isReadyForProgramming;
            this.connectionStatus.left.isPhysicallyConnected = isPhysicallyConnected;
        }
        if (this.assignedHearingAids.right?.id === hearingAidId) {
            this.connectionStatus.right.id = hearingAidId;
            this.connectionStatus.right.isReadyForProgramming = isReadyForProgramming;
            this.connectionStatus.right.isPhysicallyConnected = isPhysicallyConnected;
        }
    }

    getConnectionChangeProperties(): { [name: string]: any } {
        return {
            fitting_workflow: this.targetChoice,
            connection_status: {
                id_l: this.connectionStatus.left.id,
                id_r: this.connectionStatus.right.id,
                ready_for_programming_l: this.connectionStatus.left.isReadyForProgramming,
                ready_for_programming_r: this.connectionStatus.right.isReadyForProgramming,
                is_physically_connected_l: this.connectionStatus.left.isPhysicallyConnected,
                is_physically_connected_r: this.connectionStatus.right.isPhysicallyConnected,
            },
        };
    }

    getHearingAidsProperties(): { [name: string]: string } {
        return {
            id_r: this.assignedHearingAids?.right?.id ?? "NA",
            id_l: this.assignedHearingAids?.left?.id ?? "NA",
            ha_serial_number_r: this.assignedHearingAids?.right?.serialNumber ?? "NA",
            ha_serial_number_l: this.assignedHearingAids?.left?.serialNumber ?? "NA",
            ha_brand:
                this.assignedHearingAids?.right?.productInfo.brand ??
                this.assignedHearingAids?.left?.productInfo.brand ??
                "NA",
            ha_platform: "DChipset",
            ha_family:
                this.assignedHearingAids?.right?.family.toString() ??
                this.assignedHearingAids?.left?.family.toString() ??
                "NA",
            ha_model_l:
                this.assignedHearingAids?.left?.productInfo.productName?.split(" ")?.[0] ?? "NA",
            ha_model_r:
                this.assignedHearingAids?.right?.productInfo.productName?.split(" ")?.[0] ?? "NA",
            ha_performance_l:
                this.assignedHearingAids?.left?.productInfo.productName?.split(" ")?.[1] ?? "NA",
            ha_performance_r:
                this.assignedHearingAids?.right?.productInfo.productName?.split(" ")?.[1] ?? "NA",
            firmware_version_l: this.assignedHearingAids?.left
                ? this.hiFirmwareVersion?.left ?? "NA"
                : "NA",
            firmware_version_r: this.assignedHearingAids?.right
                ? this.hiFirmwareVersion?.right ?? "NA"
                : "NA",
            version_no: "V1",
        };
    }

    getHearingAidsAndVersionProperties(): { [name: string]: string } {
        return {
            ...this.getHearingAidsProperties(),
            ...this.getVersionProperties(),
        };
    }

    getVersionProperties(): { [name: string]: string } {
        return {
            software_identification: "Widex Compass Cloud",
            software_version_frontend: EnvironmentInfoService.frontEnd(),
            software_version_backend: `v${this.backendVersion ?? "NA"}`,
        };
    }
}

export const telemetryCustomDataProvider = new TelemetryCustomDataProvider();
